import { AddAlert } from '../../../components';
import { paramsApi } from '../../../helpers';
import api from '../../../helpers/api';
import apiAuth from '../../../helpers/apiAuth';

export const setSenhaVisivel = e => ({
  type: 'SENHA_VISIVEL',
  payload: e
})

export const setChangeForm = e => ({
  type: 'LOGIN_CHANGE_INFO',
  payload: e
})

export const setChangeEsqueci = e => ({
  type: 'LOGIN_CHANGE_ESQUECI',
  payload: e
})

export const setChangeRecuperar = e => ({
  type: 'LOGIN_CHANGE_RECUPERAR',
  payload: e
})

export const setError = e => ({
  type: 'LOGIN_ERROR',
  payload: e
})

export const enviarEmailRecuperarSenha = ({params}) => {
  let paramsUrl = paramsApi({ 
    ...params
  })
  return async dispatch => {
    apiAuth.post(`/Auth/ForgetPassword${paramsUrl}`)
    .then(result => {
      dispatch(AddAlert('success', 'E-mail enviado com sucesso! veja sua caixa de e-mail.'))
    })
    .catch(err => {
        console.error(err);
    });
  }
}
// ?UsuarioID=1&NovaSenha=a&ConfirmarSenha=h
export const mudarSenhaRecuperarSenha = ({params}) => {
  let paramsUrl = paramsApi({
    ...params
  })
  return async dispatch => {
    apiAuth.post(`/Auth/ResetPassword`, params)
    .then(result => {
      console.log(result, 'enviarEmailRecuperarSenha');
    })
    .catch(err => {
        console.error(err);
    });
  }
}
