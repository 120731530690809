import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Button, GraficoHeatmap, IcoDownload } from '../../../components';
import { convertChart, history } from '../../../helpers';

export default () => {
  const dispatch = useDispatch()
  const { graficos } = useSelector(state=> state.indicadoresState)
  const { titlePage } = useSelector(state=> state.layoutState)
  const [download, setDownload] = useState(false)

  const handleDownload = async (e) => {
    setDownload(true)
    const chartdownload = await convertChart({id: e, titlePage})
    setDownload(chartdownload)
  }

  return (
    <>
    {graficos?.map(grafico=>{
      return <div 
        key={grafico.categoriaId}  
        id={`graficos-chart-box-${grafico.categoriaId}`}  
        className={`box-indicadores-graficos box-content ${download?'graficos-download':''}`}
      >
        <div className='graficos-chart-box-action-title'>
          {download?<h3>{titlePage} - <small>{grafico.descricaoCategoria}</small></h3>:<h3>{grafico.descricaoCategoria}</h3>}
          {grafico.listaDeIndicadoresLojas?
            <Button 
              type='btn circle' 
              color='primary' 
              variant='outlined' 
              onClick={()=> handleDownload(`graficos-chart-box-${grafico.categoriaId}`)} 
              title='Download gráfico'>
                <IcoDownload />
            </Button>
            :null}
        </div>
        <GraficoHeatmap 
          grafico={grafico.listaDeIndicadoresLojas} 
          hint={true}
        />
      </div>
    })}
    </>
  )
}
