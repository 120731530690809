import React, { useEffect } from 'react'
import { Router, Route, Switch, HashRouter } from 'react-router-dom';
import { history } from '../helpers/history';
import AuthLayout from '../layout/layout';
import { titlePage } from '../layout/redux/layoutActions';
import { menuItens } from '../layout/menuItens';
import { useDispatch } from 'react-redux';

import errorPage from './errorPage';
import emDesenvolvimento from './emDesenvolvimento';
import login from '../modules/login/login';
import recupararSenha from '../modules/login/recupararSenha';

import basededados from '../modules/basededados/basededados';

import csatcliente from '../modules/empresa/csatcliente/csatcliente';
import npscliente from '../modules/empresa/npscliente/npscliente';
import rankingclientecsat from '../modules/empresa/rankingclientecsat/rankingclientecsat';
import rankingclientenps from '../modules/empresa/rankingclientenps/rankingclientenps';
import csatconcorrentes from '../modules/empresa/csatconcorrentes/csatconcorrentes';

import infoGrupo from '../modules/grupos/infoGrupo/infoGrupo';
import rankingcsat from '../modules/grupos/rankingcsat/rankingcsat';
import rankingnps from '../modules/grupos/rankingnps/rankingnps';
import indicadores from '../modules/grupos/indicadores/indicadores';
import swot from '../modules/grupos/swot/swot';

import comentarios from '../modules/comentarios/comentarios';
import npsconcorrentes from '../modules/empresa/npsconcorrentes/npsconcorrentes';

const PrivateRoute = ({ component: Component, path }) => {
  const dispatch = useDispatch()
  const pathname =  window.location.pathname
  
  const scrollToTop = () => {
    const content = document.getElementById('box-container')
    content?.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  };

  useEffect(()=> {
    dispatch(titlePage({pathname, menuItens: menuItens()}))
    scrollToTop()
  },[pathname])

  return (
    <Route
      exact
      path={path}
      render={props =>
        <AuthLayout>
          <Component {...props} />
        </AuthLayout>
      }
    />
  )
}

export default () => {
  return (
    <Router history={history}>
      <Switch>
        <Route path='/' exact component={login} />
        <Route path='/login' exact component={login} />
        <Route path='/recuperarsenha' exact component={recupararSenha} />
        <Route path='/recuperarsenha/:tolkenRecuperar' exact component={recupararSenha} />
        
        <Route path='/ResetPassword' exact component={recupararSenha} />
        <Route path='/ResetPassword/:tolkenRecuperar' exact component={recupararSenha} />
        
        <PrivateRoute path='/basededados' exact component={basededados} />

        <PrivateRoute path='/csatcliente' exact component={csatcliente} />
        <PrivateRoute path='/npscliente' exact component={npscliente} />
        <PrivateRoute path='/rankingclientecsat' exact component={rankingclientecsat} />
        <PrivateRoute path='/rankingclientenps' exact component={rankingclientenps} />
        <PrivateRoute path='/csatconcorrentes' exact component={csatconcorrentes} />
        <PrivateRoute path='/npstconcorrentes' exact component={npsconcorrentes} />

        <PrivateRoute path='/infogrupo' exact component={infoGrupo} />
        <PrivateRoute path='/rankingcsat' exact component={rankingcsat} />
        <PrivateRoute path='/rankingnps' exact component={rankingnps} />
        <PrivateRoute path='/indicadores' exact component={indicadores} />
        <PrivateRoute path='/swot' exact component={swot} />
        <PrivateRoute path='/swot/:IdSwot' exact component={swot} />
        
        <PrivateRoute path='/comentarios' exact component={comentarios} />

        <PrivateRoute path='/grupo1' exact component={emDesenvolvimento} />

        <Route component={errorPage} />
      </Switch>
    </Router>
  )
}