import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Alert, Button, IcoEye, IcoEyeBlocked, IcoLogo, Input, Loading, validacaoForm, validarCampo, verificaValidacao } from '../../components'

import { setChangeForm, setError, setSenhaVisivel } from './redux/loginActions'
import './login.scss'
import { history } from '../../helpers'
import { logIn } from '../../layout/redux/layoutActions'
import imgFrame from '../../layout/img/login/frame1.jpg'

export default () => {
  const dispatch = useDispatch()
  const { load } = useSelector(state=> state.layoutState)
  const { senhaVisivel, login, erro } = useSelector(state=> state.loginState)
  const nls = require('./nls/pt-BR.json')

  const formRequired = {
    email: 'email',
    senha: ''
  }

  const handleChange = e => {
    dispatch(setChangeForm(e))
    if (formRequired[e.name]) {
      const verify = validarCampo(e)
      dispatch(setError({...erro, [verify.name]: verify.message}))
    }
  }

  const handleSee = e => {
    dispatch(setSenhaVisivel(e))
  }

  const handleLogin = e => {
    const valid = validacaoForm({formValues: e, formRequired})
    dispatch(setError(valid))
    const verify = verificaValidacao(valid)
    if (verify.value) {
      dispatch(logIn(login))
    }
  }

  return (
    <div className='box-login'>
      <Alert />
      <div className='box-login-container'>
        <div className='box-login-frame'>
          <img src={imgFrame} />
        </div>
        <div className='box-login-form'>
          <div className='box-login-content'>
            <div className='box-login-logo'>
              <IcoLogo style={{width: '262px'}} />
            </div>
            <div className='box-login-info'>
              <h2></h2>
              <h4>Experiência do consumidor em profundidade</h4>
              <p></p>
            </div>
            <Input
              label='E-mail'
              name='email'
              action={(e) => handleChange(e)}
              value={login.email}
              placeholder='Digite o email cadastrado'
              required={{
                erro,
                message: nls.mensagem,
                pattern: formRequired.email
              }}
            />
            <Input
              label='Senha'
              name='senha'
              type={!senhaVisivel.senha?'password':'text'}
              action={(e) => handleChange(e)}
              value={login.senha}
              placeholder='Digite sua senha'
              right={
                <Button type='link' onClick={()=> handleSee({name: 'senha', value: !senhaVisivel.senha})}>
                  {!senhaVisivel.senha ? <IcoEye />
                  : <IcoEyeBlocked />}
                </Button>
              }
              required={{
                erro,
                message: nls.mensagem,
                pattern: formRequired.senha
              }}
            />
            <div className='box-login-action'>
              <div>
                <small>No minimo 6 caracteres</small>
                <Button
                  type='link'
                  color='info'
                  variant='outline'
                  onClick={()=> history.push('/recuperarsenha')}
                >esqueci minha senha</Button>
              </div>
              <div className='box-login-action-btns'>
                <Button
                  color='primary'
                  variant='outlined'
                  onClick={()=> window.open('https://www.xdive.com.br/#Contato', '_blank')}
                >Cadastro</Button>
                <Button
                  color='primary'
                  onClick={()=> handleLogin(login)}
                >Entrar</Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {load? <Loading />:null}
    </div>
  )
}
