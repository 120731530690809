import { IcoComentarios, IcoDados, IcoEmpresa, IcoGrupos } from "../components"

export const menuItens = () => {
  return [
    { 
      id: "BasedeDados", 
      label: "Base de Dados", 
      go: "/basededados", 
      icon: <IcoDados /> 
    },
    { 
      id: "empresa", 
      label: "Empresa", 
      icon: <IcoEmpresa />,
      sub: [
        { 
          id: "CSATCliente", 
          label: "CSAT Cliente", 
          go: "/csatcliente",
        },
        { 
          id: "NPSCliente", 
          label: "NPS Cliente", 
          go: "/npscliente",
        },
        { 
          id: "RankingClienteCSAT", 
          label: "Ranking Cliente CSAT", 
          go: "/rankingclientecsat",
        },
        { 
          id: "RankingClienteNPS", 
          label: "Ranking Cliente NPS", 
          go: "/rankingclientenps",
        },
        { 
          id: "CSATConcorrentes", 
          label: "CSAT Concorrentes", 
          go: "/csatconcorrentes",
        },
        { 
          id: "NPSConcorrentes", 
          label: "NPS Concorrentes", 
          go: "/npstconcorrentes",
        }
      ]
    },
    { 
      id: "grupos", 
      label: "Grupos", 
      icon: <IcoGrupos />,
      sub: [
        { 
          id: "InfoGrupo", 
          label: "Info Grupo", 
          go: "/infogrupo",
        },
        { 
          id: "RankingCSAT", 
          label: "Ranking CSAT", 
          go: "/rankingcsat",
        },
        { 
          id: "RankingNPS", 
          label: "Ranking NPS", 
          go: "/rankingnps",
        },
        { 
          id: "Indicadores", 
          label: "Indicadores", 
          go: "/indicadores",
        },
        { 
          id: "SWOT", 
          label: "SWOT", 
          go: "/swot",
        }
      ]
    },
    { 
      id: "Comentarios", 
      label: "Comentários", 
      go: "/comentarios", 
      icon: <IcoComentarios /> 
    }
  ]
}